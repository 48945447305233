import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: "/",
    //   name: "index",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/society",
    //   name: "society",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/news",
    //   name: "news",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/articles",
    //   name: "articles",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/sub",
    //   name: "sub",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/gallery",
    //   name: "gallery",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    {
      path: "/item",
      name: "item",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    },
    // {
    //   path: "/memories",
    //   name: "memories",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/contact",
    //   name: "contact",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/links",
    //   name: "links",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/gallerysub",
    //   name: "gallerysub",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/galleryimage",
    //   name: "galleryimage",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    {
      path: "/file/:pageid/:fileid",
      name: "file",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    },
    {
      path: "/",
      name: "root",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    },
    {
      path: "/404-not-found",
      name: "404",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    },
    {
      path: "/:url",
      name: "page-by-url",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
