import { sync } from "vuex-router-sync"
import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Meta from "vue-meta"
import VueGtag from "vue-gtag";

import { library } from "@fortawesome/fontawesome-svg-core"
import { faSearch, faBars, faTimes, faSquareFull, faAngleRight, faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

library.add(faSearch, faBars, faTimes, faSquareFull, faAngleRight, faAngleDown)
Vue.component("font-awesome-icon", FontAwesomeIcon)

sync(store, router)

const requireComponent = require.context("./components/elements", false, /[\w-]+\.vue$/)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = fileName.replace(/^\.\/(.*)\.\w+$/, "$1")
  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.config.productionTip = false
Vue.use(Meta)

Vue.use(VueGtag, {
  config: { id: "G-28KGZG14RV",
  params: {
    send_page_view: true
  } }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
