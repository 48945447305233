import Vue from "vue"
import Vuex from "vuex"
import api from "@/services/api"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    page: {},

    site_id: "tickhillhistory",
    settings: null,
    main_pages: null,

    homeID: "BChKk26NV",
    societyID: "jpxF8gNGX",
    memoriesID: "Q17Mmhi9T",
    contactID: "kKPdQTSf-",
    linksID: "f5Zo7b9ig",
    newsID: "iRB2VLqai",
    galleryID: "B6YTLNEG-",
    articlesID: "PAdnxphIQ",
    searchID: "GHGdZJhKc"
  },
  getters: {
    isHomePage(state) {
      return state.page.ItemID && state.page.ItemID == state.homeID
    },
    isSocietyPage(state) {
      return state.page.ItemID && state.page.ItemID == state.societyID
    },

    isSearchPage(state) {
      return state.page.ItemID && state.page.ItemID == state.searchID
    },
    isMemoriesPage(state) {
      return state.page.ItemID && state.page.ItemID == state.memoriesID
    },
    isContactPage(state) {
      return state.page.ItemID && state.page.ItemID == state.contactID
    },
    isLinksPage(state) {
      return state.page.ItemID && state.page.ItemID == state.linksID
    },
    isNewsPage(state) {
      return state.page.ItemID && state.page.ItemID == state.newsID
    },
    isGalleryPage(state) {
      return state.page.ItemID && state.page.ItemID == state.galleryID
    },
    isArticlesPage(state) {
      return state.page.ItemID && state.page.ItemID == state.articlesID
    },
    isPlacesArchive(state) {
      return state.page.ItemType && state.page.ItemType.indexOf("page.placesarchive") == 0
    },
    isTickhillToday(state) {
      return state.page.ItemType && state.page.ItemType.indexOf("page.tickhilltoday") == 0
    },
    isAlbum(state) {
      return state.route.name != "file" && state.page.ItemType && state.page.ItemType.indexOf("page.album") == 0
    },
    isArticle(state) {
      return state.page.ItemType && state.page.ItemType.indexOf("page.article") == 0
    },
    isSub(state) {
      return state.page.ItemType && state.page.ItemType.indexOf("page.sub") == 0
    },
    isArticleNewsGallerySub(state) {
      return [state.articlesID, state.newsID, state.galleryID].indexOf(state.page.ParentID) > -1
    },
    isGeneralSub(state) {
      return [state.homeID, state.societyID, state.contactID, state.linksID].indexOf(state.page.ParentID) > -1
    },
    isGeneral(state) {
      return [state.societyID, state.homeID, state.contactID, state.linksID].indexOf(state.page.ItemID) > -1
    },
    isNewsSub(state) {
      return [state.newsID].indexOf(state.page.ParentID) > -1
    },
    isArticlesSub(state) {
      return [state.articlesID].indexOf(state.page.ParentID) > -1
    },
    isMemoriesSub(state) {
      return [state.memoriesID].indexOf(state.page.ParentID) > -1
    },
    isFile(state) {
      return state.route.name == "file"
    }
  },
  mutations: {
    setPage(state, page) {
      state.page = page
    },
    setSettings(state, settings) {
      state.settings = settings
    },
    setMainPages(state, main_pages) {
      state.main_pages = main_pages
    }
  },
  actions: {
    getSettings({ commit, state }) {
      return new Promise(resolve => {
        if (!state.settings)
          api.getPageByItemID("SETTINGS").then(settings => {
            commit("setSettings", settings)
            resolve()
          })
        else resolve()
      })
    },
    getMainPages({ commit, state }) {
      return new Promise(resolve => {
        if (!state.main_pages)
          api.getPages("", "page.main").then(main_pages => {
            commit("setMainPages", main_pages)
            resolve()

            var promises = []

            main_pages.forEach(page => {
              promises.push(api.getPages("", "page.sub=@" + page.ItemID).then(subs => Vue.set(page, "subs", subs)))
            })

            Promise.all(promises).then(() => {
              commit("setMainPages", main_pages)
            })
          })
        else resolve()
      })
    }
  }
})
