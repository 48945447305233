<template>
  <div>
    <div class="list" v-if="isSearchPage">
      <div>
        <h2>Search Results</h2>
      </div>
    </div>
    <div class="list" v-if="isArticlesPage || isArticlesSub">
      <div>
        <h2>Articles Menu</h2>
        <div class="items">
          <ul class="left-menu">
            <li :key="sub.ItemID" v-for="sub in page.subs">
              <page-link :href="sub.url" @click.prevent="toggle">
                {{ sub.name }}
                <font-awesome-icon
                  class="right"
                  icon="angle-right"
                  v-if="sub.articles && sub.articles.length > 0"
                />
                <font-awesome-icon
                  class="down"
                  icon="angle-down"
                  v-if="sub.articles && sub.articles.length > 0"
                />
                <ul class="left-subs" v-if="sub.articles && sub.articles.length > 0">
                  <li :key="article.ItemID" v-for="article in sub.articles">
                    <page-link :href="article.url">{{ article.name }}</page-link>
                  </li>
                </ul>
              </page-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="list" v-if="isMemoriesPage || isMemoriesSub">
      <div>
        <h2>Menu</h2>
        <div class="items">
          <ul class="left-menu">
            <li :key="sub.ItemID" v-for="sub in page.subs">
              <page-link :href="sub.url" @click.prevent="toggle">{{ sub.name }}</page-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="list" v-if="isNewsPage || isNewsSub">
      <div>
        <h2>News</h2>
        <div class="items">
          <ul class="left-menu" v-if="page.subs">
            <li :key="sub.ItemID" v-for="sub in page.subs">
              <page-link :href="sub.url" @click.prevent="toggle">
                {{ sub.name }}
                <template v-if="sub.articles && sub.articles.length">
                  <font-awesome-icon class="right" icon="angle-right" />
                  <font-awesome-icon class="down" icon="angle-down" />
                  <ul class="left-subs">
                    <li :key="article.ItemID" v-for="article in sub.articles">
                      <page-link :href="article.url">{{ article.name }}</page-link>
                    </li>
                  </ul>
                </template>
              </page-link>
            </li>
          </ul>
        </div>
        <div class="items" v-if="newsletterArchive">
          <h3>Newsletter Archive</h3>

          <ul class="left-menu">
            <li :key="year" v-for="year in newsletterYears">
              <a @click="toggle">
                {{ year }}
                <font-awesome-icon class="right" icon="angle-right" />
                <font-awesome-icon class="down" icon="angle-right" />
                <ul class="left-subs">
                  <li :key="newsletter.ItemID" v-for="newsletter in newsletterArchive[year]">
                    <page-link
                      :external="!!newsletter.pdf"
                      :to="newsletter.pdf || newsletter.url"
                    >{{ newsletter.name }}</page-link>
                  </li>
                </ul>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div
      class="list"
      v-if="this.isGalleryPage || this.isPlacesArchive || this.isTickhillToday || this.isAlbum || isFile"
    >
      <div>
        <h2>Gallery Menu</h2>
        <div class="items">
          <h3>Places Archive</h3>
          <ul class="left-menu">
            <li :key="place.ItemID" v-for="place in page.places_archive_pages">
              <page-link :href="place.url" @click="toggle">
                {{ place.name }}
                <template v-if="place.albums && place.albums.length">
                  <font-awesome-icon class="right" icon="angle-right" />
                  <font-awesome-icon class="down" icon="angle-down" />
                  <ul class="left-subs">
                    <li :key="album.ItemID" v-for="album in place.albums">
                      <page-link :href="album.url">{{ album.name }}</page-link>
                    </li>
                  </ul>
                </template>
              </page-link>
            </li>
          </ul>
        </div>
        <div class="items">
          <h3>Tickhill Today</h3>

          <ul class="left-menu">
            <li :key="today.ItemID" v-for="today in page.tickhill_today_pages">
              <page-link :href="today.url" @click="toggle">
                {{ today.name }}
                <template v-if="today.albums && today.albums.length">
                  <font-awesome-icon class="right" icon="angle-right" />
                  <font-awesome-icon class="down" icon="angle-right" />
                  <ul class="left-subs">
                    <li :key="album.ItemID" v-for="album in today.albums">
                      <page-link :href="album.url">{{ album.name }}</page-link>
                    </li>
                  </ul>
                </template>
              </page-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["page"]),
    ...mapGetters([
      "isNewsPage",
      "isGalleryPage",
      "isArticlesPage",
      "isAlbum",
      "isPlacesArchive",
      "isTickhillToday",
      "isAlbum",
      "isSub",
      "isFile",
      "isNewsSub",
      "isArticlesSub",
      "isMemoriesPage",
      "isMemoriesSub",
      "isSearchPage"
    ]),
    newsletterYears() {
      if (!this.page) return null
      if (!this.page.newsletters) return null
      if (!this.page.newsletters.length) return null

      var years = []
      this.page.newsletters.forEach(nl => {
        var year = new Date(nl.date * 1000).getFullYear()
        if (!years.filter(y => y == year).length) years.push(year)
      })

      return years.sort((a, b) => b - a)
    },
    newsletterArchive() {
      if (!this.page) return null
      if (!this.page.newsletters) return null
      if (!this.page.newsletters.length) return null

      var archive = {}

      this.page.newsletters.forEach(nl => {
        var year = new Date(nl.date * 1000).getFullYear()
        if (!archive[year]) archive[year] = []
        archive[year].push(nl)
      })

      return archive
    }
  },
  methods: {
    toggle(event) {
      event.target.classList.toggle("show")
    }
  }
}
</script>
